export enum MESSAGES {
  CANCEL_SEND_TO_APPROVAL = 'Cancel Send to Approval',
  DATA_APPROVED = 'Data Approved Successfully',
  DATA_REJECTED = 'Data Rejected Successfully',
  FAILED = 'Failed',
  FAILED_SAVED = 'Failed Saved',
  FAILED_UPDATED = 'Failed Updated',
  FILE_NOT_FOUND = 'File not found!',
  FILE_TYPE_INVALID = 'File type is invalid.',
  PLEASE_INPUT_DATA = 'Please input data',
  PLEASE_SELECT_DATA = 'Please select data',
  SERVER_ERROR = 'Sorry, something went wrong',
  SOMETHING_WRONG = 'There is something wrong',
  SUCCESSFULLY_ACTIVATE = 'Successfully Activate',
  SUCCESSFULLY_REMOVE = 'Successfully Remove',
  SUCCESSFULLY_CREATE = 'Successfully Create',
  SUCCESSFULLY_DEACTIVATE = 'Successfully Edactivate',
  SUCCESSFULLY_DELETED = 'Successfully Deleted',
  SUCCESSFULLY_PAID_OFF_TRANSACTION = 'Successfully Paid Off Transaction',
  SUCCESSFULLY_PROCCESSED = 'Successfully Processed',
  SUCCESSFULLY_REQUEST_TOPUP = 'Successfully Request Topup',
  SUCCESSFULLY_RESET_PIN = 'Successfully Reset Pin',
  SUCCESSFULLY_SAVED = 'Successfully Saved',
  SUCCESSFULLY_SENT_TO_EMAIL = 'Successfully Sent to Email',
  SUCCESSFULLY_TOPUP = 'Successfully Topup',
  SUCCESSFULLY_UPDATED = 'Successfully Updated',
  SUCCESSFULLY_UPLOADED = 'Successfully Uploaded',
  SUCCESSFULLY_SEND_INVOICE = 'Successfully Send Invoice',
  SUCCESSFULLY_REGENERATE = 'Successfully Regenerate',
}
