export interface StoragePreferenceSpecDTO {
  key: string;
}

export enum StoragePreferenceEnum {
  Auth,
  Saas,
  Language,
  StorePrelogin,
  SFGoAuth,
  BenefitAuthSupport,
  GDTransferAuth,
  TBLastTransaction,
  CurrentCountry,
  UserRoles,
}

export class CapacitorStorage {
  static readonly CapStorage = 'CapacitorStorage';
  static readonly BenefitAuthSupport = CapacitorStorage.CapStorage + '.BenefitAuthSupport';

  // private to disallow creating other instances of this type
  private constructor(public readonly value: any) {}
}
